.drive-state-cell {
	&.accepted {
		color: var(--success);
	}

	&.declined {
		color: var(--error);
	}

	&.storno {
		color: var(--error);
	}

	&.changerequested {
		color: var(--warning);
	}
}

.message {
	margin-bottom: calc(var(--margin-big) * 2);
}

.change-request-dlg {
	p {
		max-width: 300px;
		margin-bottom: calc(var(--margin-big) * 2);
	}
}

.drives-filter {
	h3 {
		margin-bottom: var(--margin-big);
		display: none;
	}

	.drives-filter-items {
		.form {
			form {
				max-width: 100%;
			}
		}
	}
}

.drives-overview {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	.drives-table-wrapper {
		width: 100%;
		overflow: hidden;
	}
}
