.house-settings-popup {
	background-color: var(--background-color-item-popup);
	padding: 0 !important;
	width: 100px;

	button {
		width: calc(100%);
		background-color: transparent;
		margin: 0;
		border: none;
		border-radius: 0;
		&:hover {
			background-color: var(--background-color-hover) !important;
		}
	}
}

.dashboard-top-user-popup {
	.user-header {
		border-bottom: var(--border);
		padding-bottom: var(--padding-small);
		.user-name {
			color: var(--color-header);
			font-size: var(--text-big);
			font-weight: bolder;
		}

		.user-email {
			color: var(--color-fine);
			font-size: var(--text-normal);
		}
	}

	.user-content {
		padding: var(--padding) 0 0 0;
		button {
			font-size: var(--text-normal) !important;
			background-color: transparent;
			width: calc(100%);
			box-shadow: none;
			margin: 0;
			border: none;
			&:hover {
				background-color: var(--background-color-hover);
			}
		}
	}
}

.dashboard {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 80px calc(100% - 80px);
	grid-template-rows: 80px calc(100% - 80px);
	grid-template-areas: "dub top" "left content";
	overflow: hidden;
	background-color: var(--background-color);

	.delete-dialog {
		.delete-text {
			color: var(--color);
		}

		.delete-footer {
			margin-top: var(--margin-big);
			display: flex;
			justify-content: flex-end;
		}
	}

	.add-dialog-footer {
		display: flex;
		justify-content: flex-end;
		margin-top: var(--margin-big);
	}

	.dashboard-top {
		grid-area: top;
		color: var(--color);
		display: flex;
		padding-right: 50px;
		padding-left: 20px;

		.dashboard-top-start {
			display: flex;
			align-items: center;

			.dashboard-nav-button {
				display: none;
			}
			.form-input {
				margin: 0;
				width: 350px;
			}

			.house-settings {
				position: relative;
				.icon-button {
					height: 39px;
					width: 39px;
					margin-left: var(--margin-big);
				}

				&.open {
					.house-settings-popup {
						display: inline-block;
					}
				}
			}
		}

		.dashboard-top-end {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			height: 100%;
			.dashboard-top-user {
				height: 100%;
				position: relative;

				.dashboard-top-user-image {
					cursor: pointer;
					overflow: hidden;
					padding: var(--padding);
					height: calc(100% - 2 * var(--padding));
					img {
						border-radius: 50px;
						height: 100%;
					}
				}
			}
		}
	}

	.dashboard-dub {
		grid-area: dub;
		color: var(--color);
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--color-header);
		font-size: var(--text-big);
		font-weight: bold;
		overflow: hidden;
		padding: 0 5px;
		img {
			width: 100%;
		}
	}

	.dashboard-left {
		grid-area: left;
		color: var(--color);
		padding-bottom: 50px;
		padding-top: 20px;

		.vertical-navigation {
			background-color: transparent;

			.vertical-navigation-item {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: var(--margin-big);
				.vertical-navigation-item-button {
					border-radius: 500px;
					display: inline-flex;
					width: 60px;
					height: calc(60px - 2 * var(--padding));
					border: var(--border);
				}
			}
		}
	}

	.dashboard-content {
		grid-area: content;
		border-radius: 30px;
		margin: 0 40px 40px 0;
		padding: var(--padding);
		background-color: var(--background-color-item);
		color: var(--color);
		box-shadow: inset 0px 0px 16px #00000077;

		.basepage {
			padding: var(--padding);
			width: calc(100% - 2 * var(--padding));
			height: calc(100% - 2 * var(--padding));

			.basepage-title {
				font-size: var(--text-bigger);
				color: var(--color-header);
				margin-bottom: var(--margin-big);
				font-weight: bolder;
			}

			.basepage-content {
				overflow-y: hidden;
				padding: var(--padding);
				height: calc(100% - 32px - 2 * var(--padding));
			}
		}
	}

	@media (max-height: 800px) {
		.home {
			.home-content {
				.home-metadata {
					padding-bottom: 0;
				}
			}
		}
	}

	@media (max-width: 600px) {
		width: calc(100% + 80px);
		grid-template-areas: "dub top top" "left content content";
		grid-template-columns: 80px 50% 50%;
		grid-template-rows: 80px calc(100% - 80px);
		margin-left: -80px;
		transition: 0.15s margin ease-in-out;

		&.nav-open {
			margin-left: 0;

			.dashboard-content {
				filter: blur(2px);
			}
		}
		.dashboard-dub {
		}

		.dashboard-top {
			.dashboard-top-start {
				.dashboard-nav-button {
					display: inline-block;
				}
			}
			.dashboard-top-end {
				display: none;
			}
		}

		.home {
			.home-content {
				.home-metadata {
					padding-right: 0;
				}
			}
		}

		.dashboard-left {
		}

		.dashboard-content {
			transition: 0.15s filter ease-in-out;
			width: calc(100% - 160px + 2 * var(--padding));
			margin: 0 20px 20px 20px;
		}
	}
}
