:root {
	--asb-red: #ca0a16;
	--asb-yellow: #ffd813;
	/* POPOVER */
	--background-color-popover: var(--background-color-item-popup);

	/* BUTTONS */
	--background-color-button: var(--background-color-item);
	--color-button: var(--text-color);
	--background-color-button-success: var(--success);
	--color-button-success: var(--text-color);
	--background-color-button-warning: var(--warning);
	--color-button-warning: var(--color-secondary);
	--background-color-button-error: var(--error);
	--color-button-error: var(--text-color);

	/* COOKIE BANNER */
	--background-color-cookie-banner: var(--background-color-item);
	--color-cookie-banner: var(--text-color);

	/* DIALOG */
	--background-color-dialog: var(--background-color-item);

	/* FORM */
	--form-input-label-color: black;
	--form-input-label-weight: 600;
	--input-color: var(--color);
	--background-color-input-hover: rgba(0, 0, 0, 0.1);
	--form-input-border-color: rgba(0, 0, 0, 0.6);
	--form-input-border-weight: 1px;
	--form-input-border: var(--form-input-border-weight) solid var(--form-input-border-color);
	--input-required-font-size: 0.5rem;
	--input-required-color: var(--error);
	--form-input-button-background-color: var(--background-color-input-hover);
	--form-input-submit-background-color: var(--success);
	--form-input-reset-background-color: var(--error);
	--form-input-disabled-background-color: var(--background-color-input-hover);
	--input-checkbox-focused-shadow: 0 0 10px var(--color);

	/* FORM PAGER */
	--background-color-form-pager-header: var(--background-color-item-popup);

	/* HORIZONTAL NAVIGATION */
	--background-color-horizontal-navigation: var(--background-color-item);

	/* HORIZONTAL NAVIGATION */
	--background-color-vertical-navigation: var(--background-color-item);
	--background-color-vertical-navigation-button-selected: var(--background-color-highlighted);

	/* TABLE */
	--background-color-table-header: var(--asb-yellow);
	--background-color-table-body: var(--background-color-item);
	--color-table-header: var(--color-header);
	--header-table-weight: bold;

	/* MESSAGE BOX */
	--message-box-shadow: none;

	--message-box-info-color: darkblue;
	--message-box-info-background-color: #00f7f744;
	--message-box-info-border-color: darkblue;

	--message-box-warning-color: var(--warning);
	--message-box-warning-background-color: var(--warning-fine);
	--message-box-warning-border-color: var(--warning);

	--message-box-error-color: var(--error);
	--message-box-error-background-color: var(--error-fine);
	--message-box-error-border-color: var(--error);

	--message-box-success-color: var(--success);
	--message-box-success-background-color: var(--success-fine);
	--message-box-success-border-color: var(--success);

	/* HYPERTEXT */
	--background-hypertext-color: var(--background-color-popover);
	--color-hypertext: var(--color);

	/* LOG PANE */
	--log-pane-marked-shadow: var(--inner-shadow);
}

@media (prefers-color-scheme: dark) {
	:root {
		--asb-red: #ca0a16;
		--asb-yellow: #ffd813;

		/* POPOVER */
		--background-color-popover: var(--background-color-item-popup);

		/* BUTTONS */
		--background-color-button: var(--background-color-item);
		--color-button: var(--text-color);
		--background-color-button-success: var(--success);
		--color-button-success: var(--text-color);
		--background-color-button-warning: var(--warning);
		--color-button-warning: var(--color-secondary);
		--background-color-button-error: var(--error);
		--color-button-error: var(--text-color);

		/* COOKIE BANNER */
		--background-color-cookie-banner: var(--background-color-item);
		--color-cookie-banner: var(--text-color);

		/* DIALOG */
		--background-color-dialog: var(--background-color-item);

		/* FORM */
		--form-input-label-color: white;
		--form-input-label-weight: 600;
		--input-color: var(--color);
		--background-color-input-hover: rgba(255, 255, 255, 0.2);
		--form-input-border-color: rgba(255, 255, 255, 0.6);
		--form-input-border-weight: 1px;
		--form-input-border: var(--form-input-border-weight) solid var(--form-input-border-color);
		--input-required-font-size: 0.5rem;
		--input-required-color: var(--error);
		--form-input-button-background-color: var(--background-color-input-hover);
		--form-input-submit-background-color: var(--success);
		--form-input-reset-background-color: var(--error);
		--form-input-disabled-background-color: var(--background-color-input-hover);
		--input-checkbox-focused-shadow: 0 0 10px var(--color);

		/* FORM PAGER */
		--background-color-form-pager-header: var(--background-color-item-popup);

		/* HORIZONTAL NAVIGATION */
		--background-color-horizontal-navigation: var(--background-color-item);

		/* HORIZONTAL NAVIGATION */
		--background-color-vertical-navigation: var(--background-color-item);
		--background-color-vertical-navigation-button-selected: var(--background-color-highlighted);

		/* TABLE */
		--background-color-table-header: var(--asb-yellow);
		--background-color-table-body: var(--background-color-item);
		--color-table-header: var(--color-header);
		--header-table-weight: bold;

		/* MESSAGE BOX */
		--message-box-shadow: var(--shadow);

		--message-box-info-color: #00f7f7;
		--message-box-info-background-color: #00f7f744;
		--message-box-info-border-color: #00f7f7;

		--message-box-warning-color: var(--warning);
		--message-box-warning-background-color: var(--warning-fine);
		--message-box-warning-border-color: var(--warning);

		--message-box-error-color: var(--error);
		--message-box-error-background-color: var(--error-fine);
		--message-box-error-border-color: var(--error);

		--message-box-success-color: var(--success);
		--message-box-success-background-color: var(--success-fine);
		--message-box-success-border-color: var(--success);

		/* HYPERTEXT */
		--background-hypertext-color: var(--background-color-popover);
		--color-hypertext: var(--color);

		/* LOG PANE */
		--log-pane-marked-shadow: inset 0 0 8px var(--asb-red);
	}
}
