.personal-user {
	display: flex;
	gap: var(--padding);
	margin-top: var(--margin-big);

	.change-password-validations {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.NEUTRAL {
			color: var(--color-fine);
		}

		.VALID {
			color: var(--success);
		}

		.INVALID {
			color: var(--error);
		}
	}
}
