.locations-swipe-add {
	display: flex;
	flex-direction: column;

	> .kl-button {
		text-decoration: underline;
	}
}

.locations-swipe-file {
	p {
		max-width: 300px;
		margin-bottom: calc(var(--margin-big) * 2);
	}
}

.locations-institution-select {
	background-color: var(--message-box-info-background-color);
	padding: var(--padding);
	margin-bottom: var(--margin-big);
	width: calc(100% - var(--padding) * 2);

	input::placeholder {
		color: var(--message-box-info-color);
	}
}

.locations {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;

	.locations-table {
		flex-grow: 1;
		overflow: hidden;
	}
}

.locations-delete-footer {
	display: flex;
	justify-content: center;
	margin-top: var(--margin);
}
