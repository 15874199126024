.drive-form {
	height: 100%;
	overflow: auto;
	.drive-forms {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: stretch;
		gap: var(--padding-big);

		form {
			width: 300px;
		}
	}

	.drive-submit {
		display: flex;
		justify-content: center;
		margin-top: var(--margin-big);
	}
}
